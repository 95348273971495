import React from 'react';
import { BaseUrlContext, baseUrls } from '../context/base-url-context';
import { Grid, Modal } from '@mui/material';
import Tag404 from '../custom/default/Tag404';
import { ProcessState, WorkflowManager } from '../workflow/Manager';
import GeneralDialog from './GeneralDialog';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { stat } from 'fs';

// props
interface Props {
  base_url_api: string;
  base_url_hub: string;
  workflows: any[];
  theme: any;
  device_id: string;
  setBarButton: any;
  link_obj: any;
  account_id: any;
  facility_id: any;
}

class Workflow extends React.Component<Props, any> {
  flex_form_tag: any;
  flex_form_key: any;
  constructor(props: any) {
    super(props);
    // set state
    this.state = {
      form_id: '',
      load_number: '',
      component_name: null,
      component: React.Suspense,
      workflowManager: null,
      stepMatch: null,
      workflowMatch: null,
      workflowName: null,
      loading: null,
      initialized: false,
      workflow_form_map: [],
      show_modal: false,
      last_api_response: {},
      api_responses: [],
      modal_type: 'ERROR',
      error_count: 3,
      countdown: 5
    };
    this.flex_form_tag = React.createRef();
    this.flex_form_key = 0;
  }

  callAPI = async (url: string, method: string, body: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    body['facility_of_origin_id'] = !!this.props.facility_id
      ? this.props.facility_id
      : urlParams.get('fac');
    body['account_id'] = !!this.props.account_id
      ? this.props.account_id
      : urlParams.get('acc');
    // NOTE: what do we want for the env default (won't always be in url params)
    body['env'] = !!this.props.base_url_api ? this.props.base_url_api : '';
    // environment: req.query['env']
    const response = await fetch(this.props.base_url_api + url, {
      method: method,

      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Device-Id': this.props.device_id,
        'Workflow-Name': this.state.workflowName
      },
      body: JSON.stringify(body)
    });
    const json = await response.json();
    return { data: json, status: response.status };
  };

  initialize() {
    let workflow;
    let should_push_step_to_url = false;
    if (!!this.props.link_obj && 'workflow_name' in this.props.link_obj) {
      workflow = this.props.link_obj['workflow_name'];
      should_push_step_to_url = true;
    } else {
      // get page path
      workflow = window.location.pathname.split('/w/')[1];
    }
    // find the page
    workflow = workflow || 'default';

    let search = window.location.search;
    let searchParams = new URLSearchParams(search);
    let step = searchParams.get('s') || '';

    let workflowMatch = this.props.workflows.find((w: any) => {
      return w.workflow_url_fragment === workflow;
    });

    const loading = <div>Loading...</div>;

    if (workflowMatch) {
      let stepMatch: { step_id: ProcessState } | { step_id: ProcessState.INIT_BROWSER };
      if (step) {
        stepMatch = workflowMatch.steps.find((s: any) => {
          return s.step_code === step;
        });
      } else {
        stepMatch = workflowMatch.steps[0];
      }

      const workflowManager = new WorkflowManager(
        workflowMatch.workflow_id,
        workflowMatch.driver_workflow_id
      );
      workflowManager.bind((ProcessState: ProcessState) => {
        let stepMatch = workflowMatch.steps.find((s: any) => {
          return s.step_id === ProcessState;
        });
        this.setState({
          stepMatch: stepMatch
        });

        /*

                    capture api steps by calling the API and sending the response to the workflow manager
                     {
              "step_id": 25,
              "step_code": "API",
              "api_url": "/api/v1/auto_checkin",
              "api_method": "POST",
              "fields": ["seal_number"]

            },

                 */
        if (stepMatch.step_code === 'API') {
          // build fields from previous steps
          let fields: any = {};
          // loop through all api responses, which are also arrays
          this.state.api_responses.forEach((responses: any) => {
            // loop through each response
            responses.forEach((response: any) => {
              // loop through each field
              Object.keys(response['request_body']).forEach((key: any) => {
                // stepmatch fields are arrays

                if (stepMatch.fields.includes(key)) {
                  fields[key] = response['request_body'][key];
                }
              });
            });
          });

          this.callAPI(stepMatch.api_url, stepMatch.api_method, fields).then(
            (response: any) => {
              let new_saved_response = {};
              new_saved_response['HTTP_STATUS_CODE'] = response.status;
              new_saved_response['data'] = response.data;
              new_saved_response['api'] = stepMatch.api_url;
              new_saved_response['request_body'] = fields;

              this.storeAPIResponse([new_saved_response]);
              this.setState(
                {
                  last_api_response: new_saved_response
                },
                () => {
                  // call next step
                  this.state.workflowManager.forward(response.status);
                }
              );
            }
          );
        }

        // get form_id from workflow_form_map

        const form_id =
          this.state.workflow_form_map.find((f: any) => {
            return f.step_id === stepMatch.step_id;
          })?.form_id || '';

        this.setState(
          {
            form_id: form_id,
            form_type: stepMatch.step_form_type
          },
          () => {
            const url = new URL(window.location.href);
            url.searchParams.set('s', stepMatch.step_code);
            if (should_push_step_to_url) {
              window.history.pushState({}, '', url.toString());
            }
          }
        );
      });
      // set workflow map from session storage
      const workflow_map = JSON.parse(
        sessionStorage.getItem('workflow_form_map_' + workflowMatch.driver_workflow_id) ||
          '[]'
      );

      const form_id =
        workflow_map.find((f: any) => {
          return f.step_id === stepMatch.step_id;
        })?.form_id || '';
      workflowManager.set(stepMatch.step_id);

      let workflowName = workflowMatch.workflow_url_fragment;
      if (workflowName[workflowName.length - 1] === '/') {
        workflowName = workflowName.slice(0, -1);
      }
      this.setState({ workflowName: workflowName });

      this.setState({
        workflowManager: workflowManager,
        stepMatch: stepMatch,
        workflowMatch: workflowMatch,
        workflowName: workflowName,
        loading: loading,
        initialized: true,
        form_id,
        workflow_form_map: workflow_map
      });
    }
  }

  componentDidMount(): void {
    if (!!this.props.link_obj) {
      if (this.state.initialized === false) {
        this.initialize();
      }
    }
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<any>,
    snapshot?: any
  ) {
    if (this.state.initialized === false) {
      this.initialize();
    }
  }

  storeAPIResponse(response: any) {
    this.setState({
      api_responses: [...this.state.api_responses, response]
    });
  }

  render() {
    if (!this.state.workflowMatch) {
      return <Tag404 />;
    }

    if (this.state.stepMatch.step_code === 'API') {
      return <div>Loading...</div>;
    }

    // // import flexible form componen based on workflow step
    const FlexFormTag = React.lazy(() =>
      import(`../common/${this.state.stepMatch.step_component}`).catch((e) => {
        return import(`../custom/default/Tag404`);
      })
    );

    if (!this.state.initialized) {
      return <div>Loading...</div>;
    }

    const style = {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3
    };
    const modal = (
      <Modal
        open={this.state.show_modal}
        onClose={() => {
          this.setState({ show_modal: false });
        }}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
          <h2 id="parent-modal-title"></h2>
          <p id="parent-modal-description">
            {this.state.modal_type === 'ERROR' &&
              'There was an error processing your request. Please try again.'}
            {this.state.modal_type === 'COUNTDOWN' && (
              <Grid>
                <p>
                  Input error. Please try again. You have {this.state.error_count}{' '}
                  attempts left.
                </p>
              </Grid>
            )}
            {this.state.modal_type === 'BACKOFF' && (
              <Grid>
                <p>
                  Input error. Please try again. You have used {this.state.error_count}/3
                  attempts.
                </p>
              </Grid>
            )}
            {this.state.modal_type === 'BACKOFF_COUNTDOWN' && (
              <Grid>
                <p>
                  Maximum attempts reached. Please wait while the system resets in{' '}
                  {this.state.countdown} seconds
                </p>
              </Grid>
            )}
            {this.state.modal_type === 'SUCCESS_COUNTDOWN' && (
              <Grid>
                <p>
                  Maximum attempts reached. Please wait while the system resets in{' '}
                  {this.state.countdown} seconds
                </p>
              </Grid>
            )}
            {this.state.modal_type === 'SUCCESS' && (
              <Grid>
                <p>
                  Please click the link in your text message to access your confirmation
                  and signed BOL. Please present the confirmation screen to the dock
                  coordinator This page will reload in {this.state.countdown} seconds
                </p>

                <Button
                  onClick={() => {
                    // delete all local and session storage
                    sessionStorage.clear();
                    localStorage.clear();
                    // delete cookies
                    document.cookie.split(';').forEach((c) => {
                      document.cookie = c;
                    });
                    // reload page
                    window.location.reload();
                  }}
                >
                  Exit
                </Button>
              </Grid>
            )}
          </p>
        </Box>
      </Modal>
    );

    const showSuccessModalRoutes = ['/orchestration/v1/driver_signature'];

    const showErrorRoutesCountdown = [
      '/api/v1/driver_mfa_sync',
      '/api/v1/seal_validation',
      '/api/v1/driver_checkin',
      '/api/v1/auth_fr_id_validation'
    ];

    const showErrorRoutesBackoffCountdown = ['/api/v1/auth_seal_validation'];

    const showErrorRoutes = ['/api/v1/driver_assignment'];

    // don't allow scroll and put the button on the bottom for agreement
    return (
      <header>
        <Grid>
          <React.Suspense fallback={this.state.loading}>
            <FlexFormTag
              key={this.flex_form_tag.current?.getKey}
              ref={this.flex_form_tag}
              setBarButton={this.props.setBarButton}
              device_id={this.props.device_id}
              base_url_api={this.props.base_url_api}
              base_url_hub={this.props.base_url_hub}
              form_id={this.state.form_id}
              form_type={this.state.stepMatch.step_form_type}
              workflow_name={this.state.workflowName}
              next={(api_responses: any) => {
                this.storeAPIResponse(api_responses);
                this.props.setBarButton('', false);
                if (api_responses.length === 0) {
                  return;
                }

                if (
                  api_responses[api_responses.length - 1].HTTP_STATUS_CODE > 400 &&
                  showErrorRoutesCountdown.includes(
                    api_responses[api_responses.length - 1].api.route
                  )
                ) {
                  // show modal
                  this.setState(
                    {
                      show_modal: true,
                      modal_type: 'COUNTDOWN',
                      error_count: this.state.error_count - 1
                    },
                    () => {
                      if (this.state.error_count === 0) {
                        // @ts-ignore
                        if (window.Android) {
                          window.location.reload();
                        } else {
                          window.location.assign(window.location.href.split('?')[0]);
                        }
                      }
                    }
                  );
                } else if (
                  api_responses[api_responses.length - 1].HTTP_STATUS_CODE > 400 &&
                  api_responses[api_responses.length - 1].HTTP_STATUS_CODE < 429 &&
                  showErrorRoutesBackoffCountdown.includes(
                    api_responses[api_responses.length - 1].api.route
                  )
                ) {
                  // show modal
                  this.setState({
                    error_count:
                      api_responses[api_responses.length - 1]['data']['attempts'],
                    show_modal: true,
                    modal_type: 'BACKOFF'
                  });
                } else if (
                  api_responses[api_responses.length - 1].HTTP_STATUS_CODE === 429 &&
                  showErrorRoutesBackoffCountdown.includes(
                    api_responses[api_responses.length - 1].api.route
                  )
                ) {
                  // show modal
                  this.setState(
                    {
                      show_modal: true,
                      countdown: 5,
                      modal_type: 'BACKOFF_COUNTDOWN'
                    },
                    () => {
                      // delete session storage, local storage, and cookies then reload after 5 seconds.  every second update the countdown
                      // @ts-ignore
                      if (window.Android) {
                        sessionStorage.clear();
                        localStorage.clear();
                        // delete cookies
                        document.cookie.split(';').forEach((c) => {
                          document.cookie = c;
                        });
                      }

                      // reload page
                      setTimeout(() => {
                        // @ts-ignore
                        if (window.Android) {
                          window.location.reload();
                        } else {
                          //load page without query params for qr code flow
                          window.location.assign(window.location.href.split('?')[0]);
                        }
                      }, 5000);
                      setInterval(() => {
                        this.setState({ countdown: this.state.countdown - 1 });
                      }, 1000);
                    }
                  );
                } else if (
                  api_responses[api_responses.length - 1].HTTP_STATUS_CODE > 400 &&
                  showErrorRoutes.includes(
                    api_responses[api_responses.length - 1].api.route
                  )
                ) {
                  // show modal
                  this.setState({ show_modal: true, modal_type: 'ERROR' });
                } else if (
                  showSuccessModalRoutes.includes(
                    api_responses[api_responses.length - 1].api.route
                  )
                ) {
                  this.setState(
                    { countdown: 15, show_modal: true, modal_type: 'SUCCESS' },
                    () => {
                      // @ts-ignore
                      if (window.Android) {
                        sessionStorage.clear();
                        localStorage.clear();
                        // delete cookies
                        document.cookie.split(';').forEach((c) => {
                          document.cookie = c;
                        });
                      }

                      // reload page
                      setTimeout(() => {
                        // @ts-ignore
                        if (window.Android) {
                          window.location.reload();
                        } else {
                          window.location.assign(window.location.href.split('?')[0]);
                        }
                      }, 15000);
                      setInterval(() => {
                        this.setState({ countdown: this.state.countdown - 1 });
                      }, 1000);
                    }
                  );
                } else {
                  // const form_id = this.state.workflow_form_map.find((f: any) => {
                  //     return f.step_id === this.state.stepMatch.step_id
                  // })?.form_id || ""
                  // this.setState({form_id: form_id}, () => {
                  this.setState(
                    {
                      last_api_response: api_responses[api_responses.length - 1]
                    },
                    () => {
                      this.state.workflowManager.forward(
                        api_responses[api_responses.length - 1].HTTP_STATUS_CODE
                      );
                    }
                  );
                }
                // })
                // find matching step when next is
              }}
              api_responses={this.state.api_responses}
              last_api_response={this.state.last_api_response}
              set_load_number={(load_number: string) => {
                this.setState({ load_number: load_number });
              }}
              load_number={this.state.load_number}
              back={() => {
                this.state.workflowManager.backward();
              }}
              set_form_id={(form_id: number) => {
                if (form_id === this.state.form_id) {
                  return;
                }
                const workflow_map = this.state.workflow_form_map;
                workflow_map.find((f: any) => {
                  if (f.step_id === this.state.stepMatch.step_id) {
                    delete f.id;
                  }
                });
                this.setState(
                  {
                    form_id: form_id,
                    workflow_form_map: [
                      ...workflow_map,
                      {
                        form_id: form_id,
                        step_id: this.state.stepMatch.step_id
                      }
                    ]
                  },
                  () => {
                    // save to session storage
                    sessionStorage.setItem(
                      'workflow_form_map' + this.state.workflowMatch.driver_workflow_id,
                      JSON.stringify(this.state.workflow_form_map)
                    );
                  }
                );
              }}
              account_id={this.props.account_id}
              facility_id={this.props.facility_id}
            />
          </React.Suspense>
        </Grid>
        {modal}
      </header>
    );
  }
}

export default Workflow;
